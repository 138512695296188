import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import * as styles from "./dissection-points.module.scss";

const getPinKey = (id) => `pinKey-${id}`;

const DissectionPoints = ({
  activePin,
  canvas,
  pageIndex,
  pins,
  setActivePin,
  setShowDetailModal,
  setTurningThePageHeight,
  turningThePage,
}) => {
  const desktopPinsRef = useRef();
  const imageURL = canvas?.img?.split("\"")[1];

  const updatePinTo = (e, index, ref, mode) => {
    activePin.pin &&
      localStorage.setItem(getPinKey(activePin.pin.entry.id), "true");

    setActivePin({
      pin: pins[index],
      index: index,
    });

    localStorage.setItem(getPinKey(pins[index].entry.id), "true");

    // If keyboard event
    if (e?.detail === 0) {
      setTimeout(() => {
        if (mode === "desktop") {
          ref.current.childNodes[index].childNodes[2].focus();
        } else {
          ref.current.childNodes[index].childNodes[0].focus();
        }
      }, 250);
    }
  };

  const mobileTransformPosition = () => {
    if (turningThePage || pins.length === 0) {
      //Don't zoom if multiple pages
      return {};
    }

    const getTranslateCoordinate = (pointMin, pointMax, translateMax) => {
      return (point) => {
        if (point < pointMin) {
          return "0%";
        } else if (point > pointMax) {
          return `${translateMax}%`;
        } else {
          return `${(point * translateMax) / 100}%`;
        }
      };
    };

    const { x, y } = activePin.pin;
    const translateXVal = getTranslateCoordinate(35, 65, -35);
    const translateYVal = getTranslateCoordinate(15, 85, -55);

    return {
      transform: `scale(1.5) translateX(${translateXVal(
        x
      )}) translateY(${translateYVal(y)})`,
    };
  };

  const mapDesktopPins = (pin, index, ref) => {
    const pinIconOffset = 30 / 2;

    const pinStyles = {
      top: `calc(${pin.y}% - ${pinIconOffset}px)`,
      left: `calc(${pin.x}% - ${pinIconOffset}px`,
    };

    const pinClasses = classNames(styles.pin, {
      [styles.pinActive]: pin === activePin.pin,
    });

    const pinSpotClasses = (pinType) =>
      classNames(styles.pinSpot, pinType, {
        [styles.pinSpotViewed]:
          pin !== activePin.pin &&
          localStorage.getItem(getPinKey(pin.entry.id)),
      });

    return (
      <li
        aria-hidden="true"
        className={pinClasses}
        key={index}
        style={pinStyles}
      >
        <button
          aria-expanded={pin === activePin.pin ? "true" : "false"}
          className={pinSpotClasses(styles.pinSpotButton)}
          data-testid="desktop-pin"
          onClick={(e) => {
            updatePinTo(e, index, ref);
            setShowDetailModal(true);
          }}
          ref={desktopPinRefs[index]}
        >
          <i aria-label={`"${pin.entry.title}" Point`} className="icon-plus" />
        </button>

        <div aria-hidden="true" className={pinSpotClasses(styles.pinSpotPlain)}>
          <i aria-label={`"${pin.entry.title}" Point`} className="icon-plus" />
        </div>
      </li>
    );
  };

  const wrapperClasses = classNames(styles.wrapper, {
    [styles.turningThePageWrapper]: turningThePage,
  });

  const desktopPinRefs = pins?.map(() => useRef(null));

  const imageRef = useRef();

  const setDimensions = () => {
    setTurningThePageHeight({
      height: imageRef?.current?.offsetHeight,
      width: imageRef?.current?.offsetWidth,
    });
  };

  useEffect(() => {
    if (pageIndex === 0) {
      window.addEventListener("resize", setDimensions);
      setDimensions();
    }
    return () => window.removeEventListener("resize", setDimensions);
  }, []);

  return (
    <div className={wrapperClasses} data-testid="dissection-points">
      <div
        className={classNames(styles.imageWrapper, {})}
        style={mobileTransformPosition()}
      >
        <ul className={styles.pinList} ref={desktopPinsRef}>
          {pins?.map((pin, index) =>
            mapDesktopPins(pin, index, desktopPinsRef)
          )}
        </ul>

        <img
          alt=""
          className={classNames(styles.image, {
            [styles.turningThePageImageCropping]: turningThePage,
          })}
          data-testid="canvas"
          onLoad={() => {
            if (pageIndex === 0) {
              setDimensions();
            }
          }}
          ref={imageRef}
          src={imageURL}
        />
      </div>
    </div>
  );
};

DissectionPoints.propTypes = {
  activePin: PropTypes.object,
  canvas: PropTypes.object.isRequired,
  pageIndex: PropTypes.number,
  pins: PropTypes.array.isRequired,
  setActivePin: PropTypes.func,
  setShowDetailModal: PropTypes.func,
  setTurningThePageHeight: PropTypes.func,
  turningThePage: PropTypes.bool,
};

export default DissectionPoints;
