import { Display, NMAAHCPropTypes } from "assets";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as styles from "./arrow-overlay.module.scss";

const ArrowOverlay = ({
  onNextClick,
  onPreviousClick,
  hasPrevious,
  hasNext,
  buttonColor,
  display,
  isDissection,
}) => {
  const arrowDisplay = classNames(Display.addClass(display));
  const previousArrowClass = classNames(
    styles.slideButton,
    styles.previous,
    arrowDisplay,
    {
      hidden: !hasPrevious,
      [styles.blackButtons]:
        buttonColor == NMAAHCPropTypes.ARROW_OVERLAY_BLACK_BUTTON,
      [styles.orangeButtons]:
        buttonColor == NMAAHCPropTypes.ARROW_OVERLAY_ORANGE_BUTTON,
    }
  );
  const nextArrowClass = classNames(
    styles.slideButton,
    styles.next,
    arrowDisplay,
    {
      hidden: !hasNext,
      [styles.blackButtons]:
        buttonColor == NMAAHCPropTypes.ARROW_OVERLAY_BLACK_BUTTON,
      [styles.orangeButtons]:
        buttonColor == NMAAHCPropTypes.ARROW_OVERLAY_ORANGE_BUTTON,
    }
  );

  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);

  return (
    <div
      className={isDissection && styles.dissection}
      data-testid="arrow-overlay"
    >
      <button
        aria-label={`Previous ${isDissection ? "Page" : "Slide"}`}
        className={previousArrowClass}
        data-testid="previous-arrow-button"
        onClick={() => onPreviousClick()}
        ref={prevButtonRef}
      >
        <i className="icon-back" />
      </button>
      <button
        aria-label={`Next ${isDissection ? "Page" : "Slide"}`}
        className={nextArrowClass}
        data-testid="next-arrow-button"
        onClick={() => onNextClick()}
        ref={nextButtonRef}
      >
        <i className="icon-forward" />
      </button>
    </div>
  );
};

ArrowOverlay.propTypes = {
  buttonColor: NMAAHCPropTypes.ArrowOverlayType,
  display: Display.PropType,
  hasNext: PropTypes.bool.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  isDissection: PropTypes.bool,
  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
};

ArrowOverlay.defaultProps = {
  hasPrevious: true,
  hasNext: true,
  buttonColor: NMAAHCPropTypes.ARROW_OVERLAY_BLACK_BUTTON,
  display: Display.HiddenMobile,
  isDissection: false,
};

export default ArrowOverlay;
