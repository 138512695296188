import { Theme } from "assets";
import classNames from "classnames";
import { Link } from "gatsby";
import * as PropTypes from "prop-types";
import React from "react";

import * as styles from "./direction-navigation-link.module.scss";

const DirectionNavigationLink = ({ className, direction, target }) => {
  const mainContainerClasses = classNames(
    {
      [styles.nextLayout]: direction !== "backward",
      [styles.mainContainer]: true,
    },
    "row middle-xs"
  );

  const textDescriptionClasses = classNames(
    {
      [styles.previousLayout]: direction === "backward",
    },
    styles.textDescription,
    "col-xs-9"
  );

  const textContainerRow = classNames("row", {
    "start-xs": direction === "backward",
  });

  const directionText = direction === "backward" ? "Back" : "Next";
  const prevImg = (
    <div className="col-xs-3 start-xs">
      <i className="icon-arrow-back" role="presentation" />
    </div>
  );
  const nextImg = (
    <div className="col-xs-3 end-xs">
      <i
        className={`icon-arrow-forward ${styles.iconForward}`}
        role="presentation"
      />
    </div>
  );

  return (
    <Link
      className={classNames(className, styles.redirectLink)}
      key={target.id}
      to={`/${target.uri}/`}
    >
      <div className={mainContainerClasses}>
        {direction === "backward" && prevImg}

        <div className={textDescriptionClasses}>
          <div className={textContainerRow}>
            <div className="col-xs-12">
              <p className={styles.directionDescription}>{directionText}</p>
            </div>
            <div className="col-xs-12">
              <h2 className={styles.titleText}>{target.title}</h2>
            </div>
          </div>
        </div>

        {direction === "forward" && nextImg}
      </div>
    </Link>
  );
};

DirectionNavigationLink.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(["forward", "backward"]).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  ...Theme.PropType,
};

DirectionNavigationLink.defaultProps = {
  className: "",
  theme: Theme.Black,
};

export default DirectionNavigationLink;
