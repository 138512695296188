import {
  NMAAHCPropTypes,
  Theme,
  ThemeContext,
  useScrollObserver,
} from "assets";
import {
  ActionButton,
  BackgroundImageWrapper,
  FormattedText,
  Tag,
  TextPill,
} from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./checkpoint.module.scss";

const Checkpoint = ({
  backgroundCover,
  backgroundImageUrl,
  buttonText,
  buttonColor,
  text,
  fontColor,
  tagIcon,
  images,
  imageAlignment,
  isScattered,
  includeSeparator,
  isNew,
  label,
  horizontalLayout,
  onButtonClick,
  tag,
  title,
  uri,
  url,
}) => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref);
  const isExternal = !uri && url;
  const { theme, fontType, pillarTitleColor } = useContext(ThemeContext);

  const titleClass = classNames(styles.title, fontType, {
    [styles.yellowTitle]: !fontColor && Theme.DarkBackgrounds.includes(theme),
  });

  const className = classNames(
    "container-fluid",
    styles.checkpoint,
    scrolledClasses
  );

  const layoutClass = classNames("row center-xs", {
    [styles.horizontalContent]:
      horizontalLayout && images?.length && text !== null,
    row: horizontalLayout && images?.length && text !== null,
  });

  return (
    <BackgroundImageWrapper
      backgroundCover={backgroundCover}
      backgroundImageUrl={backgroundImageUrl}
      className={className}
      fontColor={fontColor}
      ref={ref}
    >
      {includeSeparator && <div className={styles.separator} />}
      <div className={layoutClass} data-testid="checkpoint">
        <div
          className={classNames(styles.ctnText, "col-xs-12 col-sm-10", {
            "col-lg-offset-1 col-lg-5": horizontalLayout,
            [styles.imageLeft]: horizontalLayout && imageAlignment === "left",
          })}
        >
          {isNew && (
            <TextPill
              size={NMAAHCPropTypes.SizeSmall}
              style={fontColor || theme === "light" ? "dark" : "default"}
              text="New"
            />
          )}
          <p className={styles.eyebrow}>{label}</p>
          <FormattedText
            className={titleClass}
            outerElement={<h2 />}
            style={{ color: pillarTitleColor }}
            text={title}
            deepLink
          />
          {tag && (
            <div className={styles.tag}>
              <Tag color={buttonColor} icon={tagIcon} text={tag} />
            </div>
          )}
          <FormattedText
            className={styles.description}
            text={text}
            theme={fontColor === "light" ? "dark" : theme}
          />
          {text && (
            <div className={styles.btnWrapper}>
              <ActionButton
                color={buttonColor}
                href={isExternal ? url : null}
                icon={isExternal ? "leave-arrow" : null}
                onClick={onButtonClick}
                screenReaderText={`. Go to ${title}`}
                target={isExternal ? "_blank" : null}
                text={buttonText}
                textPlacement="left"
                to={uri ? `/${uri}` : null}
              />
            </div>
          )}
        </div>
        <div
          className={classNames(styles.imgWrapper, {
            "col-lg-5": horizontalLayout,
            [styles.gridImages]: !isScattered && images?.length > 1,
            [styles.horizontalLayout]: horizontalLayout,
          })}
        >
          <div
            className={classNames({
              [styles.scatteredPictureWrapper]:
                isScattered && images?.length > 1,
              "col-xs-10": isScattered && images?.length > 1,
              "col-lg-9": horizontalLayout && isScattered && images?.length > 1,
              [styles.gridImagesWrapper]: !isScattered && images?.length > 1,
            })}
          >
            {images?.map((img, ind) => {
              return (
                <div
                  className={classNames({
                    [styles.scatteredPicture]:
                      isScattered && images?.length > 1,
                    [styles.gridImage]: !isScattered && images?.length > 1,
                  })}
                  key={ind}
                >
                  <GatsbyImage
                    data-testid="image"
                    image={getImage(img.imageFile)}
                  />
                </div>
              );
            })}
          </div>
          {!text && (
            <div
              className={classNames(styles.btnWrapper, styles.buttonOverlay)}
            >
              <ActionButton
                color={buttonColor}
                href={isExternal ? url : null}
                icon={isExternal ? "leave-arrow" : null}
                onClick={onButtonClick}
                screenReaderText={`. Go to ${title}`}
                target={isExternal ? "_blank" : null}
                text={buttonText}
                textPlacement="left"
                to={uri ? `/${uri}` : null}
              />
            </div>
          )}
        </div>
      </div>
    </BackgroundImageWrapper>
  );
};

Checkpoint.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  buttonColor: PropTypes.oneOf(ActionButton.Colors),
  buttonText: PropTypes.string,
  fontColor: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  imageAlignment: PropTypes.string,
  images: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  includeSeparator: PropTypes.bool,
  isNew: PropTypes.bool,
  isScattered: PropTypes.bool,
  label: PropTypes.string,
  onButtonClick: PropTypes.func,
  tag: PropTypes.string,
  tagIcon: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  uri: PropTypes.string,
  url: PropTypes.string,
};

Checkpoint.defaultProps = {
  includeSeparator: true,
  isScattered: true,
};

/**
 * The GraphQL fragment for retrieving Checkpoint data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const CheckpointFragment = graphql`
  fragment CheckpointFragment on CraftAPI_componentList_checkpoint_BlockType {
    checkpointTitle
    id
    label
    text
    includeSeparator
    isNew
    fontColor
    backgroundImage {
      url
    }
    backgroundCover
    internalLink {
      ... on CraftAPI_resources_resourcesTopic_Entry {
        title
        heroIcon
      }
      uri
    }
    externalLink
    buttonText
    horizontalLayout
    isScattered
    buttonColor
    imageAlignment
    images {
      ... on CraftAPI_image_Asset {
        altText
        filename
        url
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

const convert = (data) => {
  return (
    <Checkpoint
      {...data}
      backgroundImageUrl={data.backgroundImage?.[0]?.url}
      key={data.id}
      tag={data.internalLink[0]?.title}
      tagIcon={data.internalLink[0]?.heroIcon}
      title={data.checkpointTitle}
      uri={data.internalLink?.[0]?.uri}
      url={data.externalLink}
    />
  );
};

export { CheckpointFragment, convert, Checkpoint as default };
