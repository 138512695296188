import { NMAAHCPropTypes, Theme, useWindowDimensions } from "assets";
import {
  ActionButton,
  FormattedText,
  NMAAHCModalWindow,
  VideoPlayer,
} from "atoms";
import classNames from "classnames";
import { navigate } from "gatsby";
import { Narration } from "molecules";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";

import * as styles from "./elevator.module.scss";
import reducer, { goto, next, prev } from "./elevator.reducer";
import ElevatorItem from "./elevator-item";

export const STYLE_GROW = "grow";
export const STYLE_VERTICAL_SLIDE = "verticalSlide";

const yearStringExp = /^[\d]+$/;

const getTransitionClass = (conditions) =>
  conditions.find((condition) => condition[1]())?.[0] || "";

const getTransition = (
  itemIndex,
  currentIndex,
  preload,
  finished,
  animationStyle
) => {
  const first = itemIndex === 0;
  if (animationStyle === STYLE_VERTICAL_SLIDE) {
    const conditions = [
      [styles.levelTransitionPreload, () => preload],
      [
        styles.levelTransitionFirstVerticalSlide,
        () => first && currentIndex < 0,
      ],
      [styles.levelTransitionFirstActive, () => first && currentIndex === 0],
      [
        styles.levelTransitionFirstEndVerticalSlide,
        () => first && currentIndex > 0,
      ],
      [styles.levelTransitionActive, () => itemIndex === currentIndex],
      [
        styles.levelTransitionPrevVerticalSlide,
        () => itemIndex === currentIndex - 1 && !finished,
      ],
      [
        styles.levelTransitionNextVerticalSlide,
        () => itemIndex === currentIndex + 1 && itemIndex > 1,
      ],
      [styles.levelTransitionEndVerticalSlide, () => itemIndex < currentIndex],
      [
        styles.levelTransitionStartVerticalSlide,
        () => itemIndex > currentIndex,
      ],
    ];
    return getTransitionClass(conditions);
  } else if (animationStyle === STYLE_GROW) {
    const conditions = [
      [styles.levelTransitionPreload, () => preload],
      [styles.levelTransitionFirstGrow, () => first && currentIndex < 0],
      [styles.levelTransitionFirstActive, () => first && currentIndex === 0],
      [styles.levelTransitionFirstEndGrow, () => first && currentIndex > 0],
      [styles.levelTransitionActive, () => itemIndex === currentIndex],
      [
        styles.levelTransitionPrevGrow,
        () => itemIndex === currentIndex - 1 && !finished,
      ],
      [
        styles.levelTransitionNextGrow,
        () => itemIndex === currentIndex + 1 && itemIndex > 1,
      ],
      [styles.levelTransitionEndGrow, () => itemIndex < currentIndex],
      [styles.levelTransitionStartGrow, () => itemIndex > currentIndex],
    ];
    return getTransitionClass(conditions);
  }
};

const getCenturyTransition = (
  itemIndex,
  nextCenturyIndex,
  length,
  finished
) => {
  const last = length - 1 === itemIndex;
  const penultimate = length - 2 === itemIndex;
  const finalCentury = nextCenturyIndex === -1 && !finished;
  const conditions = [
    [styles.levelTransitionEndVerticalSlide, () => finished],
    [styles.levelTransitionActive, () => finalCentury && last],
    [
      styles.levelTransitionPrevVerticalSlide,
      () => finalCentury && penultimate,
    ],
    [styles.levelTransitionEndVerticalSlide, () => finalCentury && !last],
    [styles.levelTransitionActive, () => itemIndex === nextCenturyIndex - 1],
    [
      styles.levelTransitionPrevVerticalSlide,
      () => itemIndex === nextCenturyIndex - 2,
    ],
    [
      styles.levelTransitionNextVerticalSlide,
      () => itemIndex === nextCenturyIndex && itemIndex >= 1,
    ],
    [
      styles.levelTransitionStartVerticalSlide,
      () => itemIndex >= nextCenturyIndex,
    ],
    [
      styles.levelTransitionEndVerticalSlide,
      () => itemIndex < nextCenturyIndex,
    ],
  ];
  return getTransitionClass(conditions);
};

const bindKeyUp =
  (dispatch, length) =>
    ({ key }) => {
      if (key === "ArrowLeft" || key === "ArrowUp") return dispatch(prev());
      if (key === "ArrowRight" || key === "ArrowDown") return dispatch(next());
      if (key === "e") return dispatch(goto(length - 1));
      if (key === "s") return dispatch(goto(0));
    };

const usePreloadImages = () => {
  const [preload, setPreload] = useState(true);
  const preloadComplete = () => setPreload(false);
  useEffect(() => {
    let timeout;
    if (preload) {
      timeout = window.setTimeout(preloadComplete, 0);
    }
    return () => window.clearTimeout(timeout);
  });
  return preload;
};

export const getCenturyList = (list = []) =>
  list.reduce((prevData = [], label, i) => {
    if (!yearStringExp.test(label)) return [...prevData]; // skip non-year labels
    const century = label.slice(0, -2);
    if (prevData.find((tuple) => tuple[0] === century)) return [...prevData];
    return [...prevData, [century, i]];
  }, []);

const Elevator = ({
  list,
  entryData,
  displayModal,
  keyControls,
  preloadImages,
  modalText,
  narrationTrack,
  redirectUri,
  runtime,
  animationStyle,
  videoFadeTiming,
  introVideo,
}) => {
  let videoRef = useRef();
  let preload = false;
  const [{ index, length }, dispatch] = useReducer(reducer, {
    index: -1,
    length: list.length,
  });
  const finished = index >= length;
  const [mediaState, setMediaState] = useState({
    started: false,
    durationMillis: 0,
  });

  const [muted, setMuted] = useState(true);
  const [showModal, setShowModal] = useState(displayModal);

  const [playIntroVideo, setPlayIntroVideo] = useState(
    introVideo !== undefined
  );
  const width = useWindowDimensions().width;
  const isMobile = width > 0 && width <= parseInt(styles.mobileWidth);

  if (preloadImages) {
    preload = usePreloadImages();
  }

  if (keyControls) {
    useEffect(() => {
      const onKeyUp = bindKeyUp(dispatch, length);
      window.addEventListener("keyup", onKeyUp, true);
      return () => window.removeEventListener("keyup", onKeyUp, true);
    });
  }

  useEffect(() => {
    if (finished) navigate(`/${redirectUri}/`);
  }, [finished]);

  useEffect(() => {
    let interval = null;
    if (!playIntroVideo && mediaState.started) {
      interval = window.setInterval(() => {
        dispatch(next());
      }, (mediaState.durationMillis - videoFadeTiming * 1000) / length);
    }

    return () => interval && window.clearInterval(interval);
  }, [playIntroVideo, mediaState]);

  const centuries = useMemo(
    () => getCenturyList(list.map(({ label }) => label)),
    [list]
  );

  const nextCenturyIndex = centuries.findIndex((tuple) => tuple[1] > index);

  // Moves to the first slide on first render
  useEffect(() => {
    if (introVideo && videoFadeTiming && playIntroVideo && mediaState.started) {
      let intervalID = setInterval(() => {
        setPlayIntroVideo(false);
        dispatch(next());
        clearInterval(intervalID);
      }, videoFadeTiming * 1000);
    }
  }, [mediaState]);

  useEffect(() => {
    if (!introVideo) {
      dispatch(next());
    }
    if (showModal) {
      if (introVideo) {
        videoRef?.current.pause();
      }
      setTimeout(() => {
        videoRef?.current.pause();
        setMuted(false);
      }, 500);
    } else {
      setMuted(false);
    }
  }, []);

  const videoClass = classNames({
    [styles.videoClass]: introVideo,
    [styles.floatingVideo]: !introVideo,
  });

  let titleDate = "";

  if (entryData?.startDate != null && entryData?.endDate != null) {
    const startDate = entryData?.startDate.slice(0, 4);
    const endDate = entryData?.endDate.slice(0, 4);
    titleDate = `${startDate}\u2013${endDate}`;
  }

  return (
    <div className={styles.elevator} data-testid="elevator">
      <VideoPlayer
        centerAndEnlargeVideo={Boolean(introVideo)}
        containerClass={videoClass}
        muted={muted}
        onDurationDiscover={(durationMillis) => {
          if (!displayModal) setMediaState({ started: true, durationMillis });
        }}
        ref={videoRef}
        // TODO: youtube patch
        src={introVideo || narrationTrack?.audioURL}
        videoFade={introVideo && !playIntroVideo}
        vtt={narrationTrack?.vttURL}
        playsInline
      />
      <ol>
        {list.map((entry, i) => (
          <ElevatorItem
            alt={entry.alt}
            className={getTransition(
              i,
              index,
              preload,
              finished,
              animationStyle
            )}
            image={entry.image}
            key={`${entry.label}-${i}`}
            label={entry.label}
            smallText={animationStyle === STYLE_GROW}
          />
        ))}
      </ol>
      <div aria-hidden="true">
        {centuries.map((century, i) => (
          <div
            className={`${styles.absoluteContainer} ${getCenturyTransition(
              i,
              nextCenturyIndex,
              centuries.length,
              finished
            )}`}
            key={century[0]}
          >
            <div aria-label={century[0]} className={styles.centerWrapper}>
              <span
                className={`${styles.labelText} ${styles.labelTextCentury} ${styles.centerWrapper}`}
              >
                {century[0]}
              </span>
            </div>
          </div>
        ))}
      </div>
      {redirectUri && !showModal && (
        <div className={styles.skipButtonPosition}>
          <ActionButton
            aria-label={"Skip video"}
            color={"black"}
            icon={"next-arrow-circle"}
            iconButton={isMobile}
            text={isMobile ? null : "Skip"}
            to={`/${redirectUri}`}
            stroke
          />
        </div>
      )}
      {narrationTrack && !showModal && (
        <Narration
          audioMuted={muted}
          ref={videoRef}
          setAudioMuted={setMuted}
          track={narrationTrack}
        />
      )}
      <NMAAHCModalWindow
        showClose={false}
        showModal={showModal}
        theme={Theme.Black}
      >
        <div
          className={classNames("col-xs-12 col-md-8", [styles.modalContainer])}
        >
          <FormattedText
            className={entryData?.fontType}
            outerElement={<h1 />}
            style={{ color: entryData?.fontColor || "inherit" }}
            text={entryData?.formattedTitleOverride || entryData?.title || "no"}
          />
          {titleDate && <p className={styles.titleDate}>{titleDate}</p>}
          {entryData?.subtitle && (
            <p className={styles.subtitle}>{entryData?.subtitle}</p>
          )}
          <FormattedText className={styles.modalText} text={modalText} />
          <p className={styles.runtime}>Run time: {runtime}</p>

          <ActionButton
            aria-label={"Play video"}
            data-testid="modal-play"
            icon="play"
            onClick={() => {
              setShowModal(false);
              setMediaState({
                started: true,
                durationMillis: videoRef?.current?.duration * 1000,
              });
              if (videoRef) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
              }
            }}
            screenReaderText={entryData?.title}
            text={"Play Video"}
          />
          <ActionButton
            aria-label={"Skip video"}
            color="black"
            icon="next-arrow-circle"
            screenReaderText={"Skip Video"}
            text={"Skip Video"}
            to={`/${redirectUri}`}
            stroke
          />
        </div>
      </NMAAHCModalWindow>
    </div>
  );
};

Elevator.propTypes = {
  animationStyle: PropTypes.string.isRequired,
  displayModal: PropTypes.bool,
  entryData: PropTypes.array,
  introVideo: PropTypes.string,
  keyControls: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      image: NMAAHCPropTypes.Image,
      label: PropTypes.string.isRequired,
    })
  ),
  modalText: PropTypes.string,
  narrationTrack: PropTypes.shape({
    audioURL: PropTypes.string,
    vttURL: PropTypes.string,
  }),
  preloadImages: PropTypes.bool,
  redirectUri: PropTypes.string,
  runtime: PropTypes.string,
  videoFadeTiming: PropTypes.number,
};

Elevator.defaultProps = {
  animationStyle: STYLE_VERTICAL_SLIDE,
  autoPlay: true,
  displayModal: true,
  keyControls: false,
  list: [],
  preloadImages: false,
  videoFadeTiming: 0,
};

export default Elevator;
