// extracted by mini-css-extract-plugin
export var arrowIcon = "quote-module--arrowIcon--msb7y";
export var credits = "quote-module--credits--P1iiN";
export var ctnQuoteImg = "quote-module--ctnQuoteImg--LCfrY";
export var listen = "quote-module--listen--qb8PN";
export var margins = "quote-module--margins--zd7h0";
export var quoteContainer = "quote-module--quoteContainer--gkMh0";
export var quoteContent = "quote-module--quoteContent--WWsnV";
export var quoteContentSmall = "quote-module--quoteContentSmall--+G4li";
export var section = "quote-module--section--w37Nl";
export var shareBtn = "quote-module--shareBtn--XGJ1q";
export var withComponentSpacing = "quote-module--withComponentSpacing--GRF2q";
export var wrappingImageConstraints = "quote-module--wrappingImageConstraints--rY-Z-";