// extracted by mini-css-extract-plugin
export var image = "dissection-points-module--image--6QAjP";
export var imageWrapper = "dissection-points-module--imageWrapper--NHE+Q";
export var pin = "dissection-points-module--pin--ZLt+7";
export var pinActive = "dissection-points-module--pinActive--TnVnQ";
export var pinList = "dissection-points-module--pinList--4+Nwp";
export var pinSpot = "dissection-points-module--pinSpot--PSrLi";
export var pinSpotButton = "dissection-points-module--pinSpotButton--HOUFR";
export var pinSpotPlain = "dissection-points-module--pinSpotPlain--wKQUX";
export var pinSpotViewed = "dissection-points-module--pinSpotViewed--PXDB2";
export var transform = "dissection-points-module--transform--EhMZ1";
export var turningThePageImageCropping = "dissection-points-module--turningThePageImageCropping--Kz4yx";
export var turningThePageWrapper = "dissection-points-module--turningThePageWrapper--sq6o+";
export var wrapper = "dissection-points-module--wrapper--VLvXT";