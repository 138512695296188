import { NMAAHCPropTypes, Theme } from "assets";
import { BackgroundImageWrapper, FormattedText } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { ConstrainedSizeImage, ExpandableImage, TextPromo } from "molecules";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./full-width-single-image.module.scss";

const FullWidthSingleImage = ({
  backgroundCover,
  backgroundImageUrl,
  fontColor,
  image,
  imageCaption,
  imageObjectFit,
  imageObjectPosition,
  label,
  modalCaptionOnly,
  modalText,
  componentSpacing,
  promoTitle,
  promoSummary,
  smallTitle,
  smallSubtitle,
  text,
}) => {
  const containerClass = classNames("row", styles.verticalMiddle, {
    [styles.imageComponentSpacer]: componentSpacing,
  });

  return (
    <BackgroundImageWrapper
      backgroundCover={backgroundCover}
      backgroundImageUrl={backgroundImageUrl}
      className={containerClass}
      fontColor={fontColor}
    >
      <div className="col-xs" data-testid="full-width-single-image">
        <ConstrainedSizeImage image={image}>
          {promoTitle && (
            <TextPromo
              summary={promoSummary}
              title={promoTitle}
              withComponentSpacing={false}
              fullWidth
            />
          )}
          <ExpandableImage
            image={image}
            imageCaption={imageCaption}
            imageLayout={"constrained"}
            imageObjectFit={imageObjectFit}
            imageObjectPosition={imageObjectPosition}
            label={label}
            modalCaptionOnly={modalCaptionOnly}
            modalText={modalText}
          />
          <FormattedText
            className={styles.smallTitle}
            outerElement={<h3 />}
            text={smallTitle}
          />
          {smallSubtitle && (
            <FormattedText
              className={classNames("large-font", styles.smallSubtitle)}
              outerElement={<p />}
              text={smallSubtitle}
            />
          )}
          <FormattedText className={styles.text} text={text} />
        </ConstrainedSizeImage>
      </div>
    </BackgroundImageWrapper>
  );
};

FullWidthSingleImage.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  componentSpacing: PropTypes.bool,
  fontColor: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  imageCaption: PropTypes.string,
  imageObjectFit: PropTypes.oneOf(["contain", "cover", "none", "scale-down"]),
  imageObjectPosition: PropTypes.string,
  label: PropTypes.string,
  modalCaptionOnly: PropTypes.bool,
  modalText: PropTypes.string,
  promoSummary: PropTypes.string,
  promoTitle: PropTypes.string,
  smallSubtitle: PropTypes.string,
  smallTitle: PropTypes.string,
  text: PropTypes.string,
  ...Theme.PropType,
};

FullWidthSingleImage.defaultProps = {
  theme: Theme.Dark,
  label: "Image",
  componentSpacing: true,
};

const FullWidthSingleImageFragment = graphql`
  fragment FullWidthSingleImageFragment on CraftAPI_componentList_fullWidthSingleImage_BlockType {
    id
    promoTitle
    promoSummary
    image {
      ...ImageMetadataFragment
    }
    imageCaption
    modalImageText
    smallTitle
    smallSubtitle
    text
    imageCropStyle
    backgroundCover
    backgroundImage {
      url
    }
    fontColor
  }
`;

const convert = (data) => {
  return (
    <FullWidthSingleImage
      backgroundCover={data.backgroundCover}
      backgroundImageUrl={data.backgroundImage?.[0]?.url}
      fontColor={data.fontColor}
      image={data.image?.[0]}
      imageAlt={data.image?.[0]?.altText}
      imageCaption={data.imageCaption}
      imageObjectFit={data.imageCropStyle}
      imageObjectPosition={data.imagePositionOverride}
      key={data.id}
      modalText={data.modalImageText}
      promoSummary={data.promoSummary}
      promoTitle={data.promoTitle}
      smallSubtitle={data.smallSubtitle}
      smallTitle={data.smallTitle}
      text={data.text}
    />
  );
};

export {
  convert,
  FullWidthSingleImage as default,
  FullWidthSingleImageFragment,
};
