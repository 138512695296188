import { NMAAHCPropTypes, Theme, ThemeContext } from "assets";
import { BackgroundImageWrapper, FormattedText, Listen } from "atoms";
import classNames from "classnames";
import { ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import * as styles from "./chapter-hero.module.scss";

const columnClasses = "col-xs-10 col-sm-8";

const ChapterHero = ({
  audioAsset,
  backgroundImageUrl,
  fontColor,
  heroBackgroundAlignment,
  image,
  imageObjectFit,
  imageObjectPosition,
  header,
  title,
  longDescription,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <BackgroundImageWrapper
      backgroundImageUrl={backgroundImageUrl}
      backgroundPosition={heroBackgroundAlignment}
      className={styles.chapterHero}
      fontColor={fontColor}
    >
      <div
        className={`container-fluid ${styles.innerContainer}`}
        data-testid="chapter-hero"
      >
        {image && (
          <ExpandableImage
            image={image}
            imageObjectFit={imageObjectFit}
            imageObjectPosition={imageObjectPosition}
            imageWrapperClassName={styles.imageContainer}
            modalText={image.caption}
            modalCaptionOnly
          />
        )}
        <div
          className={classNames(
            "row",
            "center-xs",
            styles.chapterTextContainer,
            Theme.addClass(Theme.Dark, true)
          )}
        >
          <div className={columnClasses}>
            <h1>
              {header && <div className={styles.header}>{header}</div>}
              {title}
            </h1>
            {audioAsset?.title && audioAsset?.url && (
              <div className={styles.listenContainer}>
                <Listen audioAsset={audioAsset} theme={Theme.Subtle} />
              </div>
            )}
          </div>
        </div>
        <div className="row center-xs">
          <FormattedText
            className={columnClasses}
            text={longDescription}
            theme={theme}
          />
        </div>
      </div>
    </BackgroundImageWrapper>
  );
};

export { ChapterHero as default };

ChapterHero.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  backgroundImageUrl: PropTypes.string,
  creditLine: PropTypes.string,
  fontColor: PropTypes.string,
  header: PropTypes.string,
  heroBackgroundAlignment: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  imageObjectFit: NMAAHCPropTypes.ImageObjectFit,
  imageObjectPosition: PropTypes.string,
  longDescription: PropTypes.string,
  title: PropTypes.string,
};
