import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { connectHighlight } from "react-instantsearch-dom";

// Documentation and examples for this are at https://www.algolia.com/doc/api-reference/widgets/highlight/react/#connector
const Highlight = ({
  highlight,
  attribute,
  hit,
  tagName,
  nonHighlightedTagName,
  href,
}) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  });

  const TagName = tagName;
  const NonHighlightedTagName = nonHighlightedTagName;

  return (
    <Link className="ais-Highlight" to={`${href}/`}>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <TagName className="ais-Highlight-highlighted" key={index}>
            {part.value}
          </TagName>
        ) : (
          <NonHighlightedTagName
            className="ais-Highlight-nonHighlighted"
            key={index}
          >
            {part.value}
          </NonHighlightedTagName>
        )
      )}
    </Link>
  );
};

const SearchHighlight = connectHighlight(Highlight);

Highlight.propTypes = {
  attribute: PropTypes.string.isRequired,
  highlight: PropTypes.func.isRequired,
  hit: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
  nonHighlightedTagName: PropTypes.string,
  tagName: PropTypes.string,
};

Highlight.defaultProps = {
  tagName: "mark",
  nonHighlightedTagName: "span",
  href: "#",
};

export default SearchHighlight;
