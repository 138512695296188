import { NMAAHCPropTypes, Theme, ThemeContext } from "assets";
import { Scroller } from "atoms";
import { ActionButton, FormattedText } from "atoms";
import classNames from "classnames";
import { ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./timeline-intro.module.scss";

const TimelineIntro = ({
  description,
  image,
  onNext,
  theme,
  title,
  bgColor,
}) => {
  const descriptionRef = useRef();
  const className = classNames("embla__slide row", styles.intro);
  const scrollerClassName = classNames(styles.scrollContainer, "hidden-mobile");
  const buttonClassName = classNames(styles.buttonContainer, "hidden-mobile");

  const boxColor = classNames("col-xs-12 col-sm-6", {
    [styles.lightBg]: bgColor === "light",
    [styles.darkBg]: bgColor === "dark",
  });

  const { fontType } = useContext(ThemeContext);

  return (
    <section className={className} data-testid="timeline-intro">
      <div className="col-xs-12 col-sm-6">
        <ExpandableImage
          image={image}
          imageObjectFit="contain"
          modalCaptionOnly
        />
      </div>
      <div className={boxColor}>
        <div data-testid="intro-title">
          <FormattedText
            className={classNames(fontType)}
            outerElement={<h2 />}
            text={title}
          />
        </div>
        <div className={styles.textAndScroll}>
          <div className={styles.textContainer} ref={descriptionRef}>
            <FormattedText text={description} theme={theme} />
          </div>
          <div className={scrollerClassName}>
            <Scroller
              direction="vertical"
              ref={descriptionRef}
              scrollBarColor={theme === Theme.Dark ? "light" : "dark"}
            />
          </div>
        </div>
        <div className={buttonClassName}>
          <ActionButton
            icon="arrow-right"
            onClick={onNext}
            text="Next"
            textPlacement="left"
          />
        </div>
      </div>
    </section>
  );
};

TimelineIntro.propTypes = {
  bgColor: PropTypes.string,
  description: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  onNext: PropTypes.func,
  theme: PropTypes.string,
  title: PropTypes.string,
};

export default TimelineIntro;
