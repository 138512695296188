import { NMAAHCPropTypes } from "assets";
import { IIIFContainer, VideoPlayer } from "atoms";
import { ContentWarning } from "atoms/image-with-caption/image-with-caption";
import classNames from "classnames";
import { CarouselWrapper } from "molecules";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as styles from "./media-carousel.module.scss";

const CarouselImage = ({ image, index }) => {
  const [showContentWarning, setShowContentWarning] = useState(
    image.sensitiveContentWarning
  );

  return (
    <div style={{ position: "relative" }}>
      {showContentWarning && (
        <ContentWarning setShowContentWarning={setShowContentWarning} />
      )}
      <IIIFContainer count={index} image={image} />
    </div>
  );
};

CarouselImage.propTypes = {
  image: NMAAHCPropTypes.Image.isRequired,
  index: PropTypes.number.isRequired,
};

const MediaCarousel = ({ media, onSelectedIndex, startIndex }) => {
  return (
    <>
      <CarouselWrapper
        fontColor={"light"} // we want to use the yellow left/right buttons
        onSelectedIndex={onSelectedIndex}
        startIndex={startIndex}
      >
        {media.map((medium, i) => (
          <div
            className={classNames("embla__slide", styles.carouselSlide, {
              [styles.videoSlide]: medium.videoUrl,
            })}
            key={i}
          >
            {medium.videoUrl || medium.video ? (
              <VideoPlayer
                containerClass={styles.video}
                muted={false}
                src={medium?.videoUrl}
                loop
                ui
              />
            ) : (
              <CarouselImage image={medium.image} index={i} />
            )}
          </div>
        ))}
      </CarouselWrapper>
    </>
  );
};

MediaCarousel.propTypes = {
  media: PropTypes.array.isRequired,
  onSelectedIndex: PropTypes.func,
  startIndex: PropTypes.number,
};

export default MediaCarousel;
