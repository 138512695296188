import { NMAAHCPropTypes, Theme, ThemeContext } from "assets";
import {
  ActionButton,
  BackgroundImageWrapper,
  FormattedText,
  TextPill,
} from "atoms";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import * as styles from "./timeline-promo.module.scss";

const TimelinePromo = ({
  description,
  image,
  onLaunch,
  title,
  backgroundImageUrl,
  backgroundCover,
  fontColor,
}) => {
  const { fontType, theme, pillarTitleColor } = useContext(ThemeContext);

  const titleClassname = classNames(styles.promoTitle, fontType, {
    [styles.darkTitle]: fontColor === "dark",
  });

  return (
    <BackgroundImageWrapper
      backgroundCover={backgroundCover}
      backgroundImageUrl={backgroundImageUrl}
      className={classNames(
        styles.promoComponent,
        Theme.addClass(Theme.Dark, true, true),
        "container-fluid"
      )}
      fontColor={fontColor}
    >
      <div
        className={classNames("row", styles.content)}
        data-testid="timeline-promo"
      >
        <div className={classNames("col-lg-5 col-xs-12", styles.colTwo)}>
          <TextPill style={fontColor} text="Interactive" withLightning />
          <span data-testid="title">
            <FormattedText
              className={titleClassname}
              outerElement={<h2 />}
              style={{ color: pillarTitleColor }}
              text={title}
              deepLink
            />
          </span>
          <FormattedText
            text={description}
            theme={fontColor === "light" ? "dark" : theme}
          />
        </div>
        <div
          className={classNames(
            "col-lg-offset-1 col-lg-5 col-xs-12",
            styles.colOne
          )}
        >
          <div className={styles.imgContainer}>
            <GatsbyImage
              alt={image.altText}
              data-testid="image"
              image={getImage(image.imageFile)}
            />
            <div className={styles.btnContainer}>
              <ActionButton onClick={onLaunch} text="Explore Timeline" />
            </div>
          </div>
        </div>
      </div>
    </BackgroundImageWrapper>
  );
};

TimelinePromo.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  description: PropTypes.string,
  fontColor: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  onLaunch: PropTypes.func,
  title: PropTypes.string,
};

TimelinePromo.defaultProps = {};

export default TimelinePromo;
