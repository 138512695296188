import classNames from "classnames";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import * as styles from "./background-image-wrapper.module.scss";

const BackgroundImageWrapper = forwardRef(
  (
    {
      backgroundCover,
      backgroundImageUrl,
      backgroundPosition,
      className,
      children,
      fontColor,
      id,
      fullWidthComponent,
    },
    ref
  ) => (
    <section
      className={classNames(
        className,
        styles[backgroundPosition],
        styles[fontColor],
        {
          [styles.backgroundImage]: backgroundImageUrl,
          [styles.backgroundCover]: backgroundCover,
        }
      )}
      data-testid="background-image-wrapper"
      id={id}
      ref={ref}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      {fullWidthComponent || !backgroundImageUrl ? (
        children
      ) : (
        <div className={styles.contentContainer}>{children}</div>
      )}
    </section>
  )
);

BackgroundImageWrapper.displayName = "BackgroundImageWrapper";

BackgroundImageWrapper.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  backgroundPosition: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.object,
  fontColor: PropTypes.string,
  fullWidthComponent: PropTypes.bool,
  id: PropTypes.string,
};

BackgroundImageWrapper.defaultProps = {
  backgroundCover: true,
};

export default BackgroundImageWrapper;
