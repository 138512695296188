import { NMAAHCPropTypes, ThemeContext, useScrollObserver } from "assets";
import { BackgroundImageWrapper, FormattedText, Listen } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./quote.module.scss";

const Quote = ({
  audioAsset,
  author,
  backgroundCover,
  backgroundImageUrl,
  date,
  bgAlign,
  fullWidth,
  quote,
  quoteImage,
  small,
  textAlignment,
  withComponentSpacing,
  fontColor,
  fontType,
  imageObjectPosition,
}) => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref);
  const { theme } = useContext(ThemeContext);

  const sectionClassName = classNames(
    styles.section,
    scrolledClasses,
    fontType
  );

  const rowClassName = classNames("row", {
    "center-xs": textAlignment === "center",
  });

  const colClassName = classNames(styles.quoteContainer, {
    "col-xs": fullWidth,
    "col-md-9": !fullWidth && textAlignment === "center",
    "col-xs-10 col-xs-offset-1": !fullWidth && textAlignment === "left",
    [styles.margins]: !fullWidth && textAlignment === "center",
  });

  return (
    <BackgroundImageWrapper
      backgroundCover={backgroundCover}
      backgroundImageUrl={backgroundImageUrl}
      backgroundPosition={bgAlign}
      className={classNames(sectionClassName, {
        [styles.withComponentSpacing]: withComponentSpacing,
      })}
      fontColor={fontColor}
      ref={ref}
    >
      {quoteImage && (
        <div className={styles.ctnQuoteImg}>
          <ExpandableImage
            image={quoteImage}
            imageObjectPosition={imageObjectPosition}
            imageWrapperClassName={styles.wrappingImageConstraints}
            modalCaptionOnly
          />
        </div>
      )}
      <div className={rowClassName}>
        <div className={colClassName}>
          <FormattedText
            className={classNames(styles.quoteContent, {
              [styles.quoteContentSmall]: small,
            })}
            outerElement={<q />}
            text={quote}
            theme={fontColor === "light" ? "dark" : theme}
          />
        </div>
      </div>
      {(author || date) && (
        <div className={rowClassName}>
          <div className={colClassName}>
            <div
              className={classNames(styles.credits, "large-font")}
              data-testid="credits"
            >
              <span />
              <FormattedText
                // filters falsy fields, joins them in a single string,
                // then finds text outside closing </p> tag and moves it inside the tag
                text={[author, date]
                  .filter(Boolean)
                  .join(", ")
                  .replace(/(<\/p>),\s*([^<]+)/g, ", $2$1")}
                theme={fontColor === "light" ? "dark" : theme}
              />
            </div>
          </div>
        </div>
      )}
      {audioAsset?.title && audioAsset?.url && (
        <div className={rowClassName}>
          <div className={classNames(styles.listen, colClassName)}>
            <Listen audioAsset={audioAsset} data-testid="listen" />
          </div>
        </div>
      )}
    </BackgroundImageWrapper>
  );
};

Quote.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  author: PropTypes.string,
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  bgAlign: PropTypes.string,
  date: PropTypes.string,
  fontColor: PropTypes.string,
  fontType: NMAAHCPropTypes.FontType,
  fullWidth: PropTypes.bool,
  galleryQuoteImageClass: PropTypes.string,
  imageObjectPosition: PropTypes.string,
  quote: PropTypes.string.isRequired,
  quoteImage: NMAAHCPropTypes.Image,
  small: PropTypes.bool,
  textAlignment: PropTypes.oneOf(["center", "left"]),
  withComponentSpacing: PropTypes.bool,
};

Quote.defaultProps = {
  fullWidth: false,
  imageObjectPosition: "center top",
  quote: "Full Width Quote",
  small: false,
  textAlignment: "center",
  withComponentSpacing: true,
};

/**
 * The GraphQL fragment for retrieving Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const QuoteFragment = graphql`
  fragment QuoteFragment on CraftAPI_componentList_quotePromo_BlockType {
    id
    backgroundCover
    backgroundImage {
      url
    }
    bgAlign
    fontColor
    fontType
    textAlignment
    referenceQuote {
      ... on CraftAPI_object_quote_Entry {
        audioAsset {
          ...AudioAssetFragment
        }
        asset {
          url
        }
        quoteAuthor
        quoteYear
        transcription
      }
    }
  }
`;

const convert = (quoteData) => {
  const { [0]: quote } = quoteData.referenceQuote;
  return (
    <Quote
      audioAsset={quote.audioAsset?.[0]}
      author={quote.quoteAuthor}
      backgroundCover={quoteData.backgroundCover}
      backgroundImageUrl={quoteData.backgroundImage?.[0]?.url}
      bgAlign={quoteData.bgAlign}
      date={quote.quoteYear}
      fontColor={quoteData.fontColor}
      fontType={quoteData.fontType}
      key={quoteData.id}
      quote={quote.transcription}
      textAlignment={quoteData.textAlignment || undefined}
    />
  );
};

export { convert, Quote as default, QuoteFragment };
