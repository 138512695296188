import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import * as styles from "./sectionnav-block.module.scss";

const SectionNavBlock = ({
  active,
  keepFocusOnClusterNav,
  onClick,
  subtitle,
  title,
  to,
}) => {
  const className = classNames(styles.navItem, "large-font", {
    [styles.active]: active,
    [styles.nonActive]: !active,
  });

  const isActive = keepFocusOnClusterNav && active ? "page" : false;
  const focusRef = useRef();

  useEffect(() => {
    keepFocusOnClusterNav && active && focusRef.current.focus();
  });

  const innards = (
    <>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      {title && <p className={classNames(styles.title)}>{title}</p>}
    </>
  );

  if (to)
    return (
      <Link
        aria-current={isActive}
        className={className}
        ref={focusRef}
        to={`${to}/`}
      >
        {innards}
      </Link>
    );
  else
    return (
      <button
        className={className}
        data-testid="section-nav-block"
        onClick={onClick}
      >
        {innards}
      </button>
    );
};

SectionNavBlock.propTypes = {
  active: PropTypes.bool,
  keepFocusOnClusterNav: PropTypes.bool,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
};

export default SectionNavBlock;
